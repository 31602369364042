import { computed, reactive, toRefs, getCurrentInstance } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const route = useRoute()
  const router = useRouter()
  const { getters } = useStore()
  const state = reactive({
    userData: computed(() => getters['user/getData']),
    rewardPoint: 0,
    cardFee: 0,
    cashFee: 0,
    form: {},
    thumbnail: [],
    pinDetail: {},
    conflict: [],
    chatroomId: '',
    showUseDt: computed(() =>
      state.pinDetail.useDt
        ? proxy.$dayjs(state.pinDetail.useDt).format('YYYY-MM-DD 까지')
        : '기한 없음'
    )
  })

  const getStatusStyle = status =>
    status == 2 || status == 4
      ? { done: true }
      : status == 1
      ? { waiting: true }
      : status == 0
      ? {}
      : { conflict: true }

  const fnConfirm = () => {
    router.go(-1)
  }

  const fnDetail = async () => {
    const params = {
      pinSeq: route.query.pinSeq,
      marketNo: route.query.marketNo,
      memberSeq: route.params.prev
    }
    const res = await proxy.$MarketSvc.postMyPageMarketPinDetail(params)
    if (res.resultCode === '0000') {
      state.pinDetail = { ...res }
      delete state.pinDetail.resultCode
      delete state.pinDetail.resultMsg
      delete state.pinDetail.conflict
      state.conflict = res.conflict
    } else {
      alert(res.resultMsg)
    }
  }

  const init = async () => {
    await fnDetail()
  }

  init()

  return {
    ...toRefs(state),
    getStatusStyle,
    fnConfirm
  }
}
