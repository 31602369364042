<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="page_con">
          <div class="detail_top">
            <div class="market_member_ttl">
              이전 거래이력
            </div>
          </div>
          <div class="lump">
            <div class="table_type01">
              <dl>
                <dt>사용 기한</dt>
                <dd>{{ showUseDt }}</dd>
              </dl>
              <dl>
                <dt>상태</dt>
                <dd :class="getStatusStyle(pinDetail.status)">
                  {{ pinDetail.statusNm }}
                </dd>
              </dl>
            </div>
            <div class="table_type02">
              <dl>
                <dt>상품명</dt>
                <dd>
                  <span>{{ pinDetail.title }}</span>
                </dd>
              </dl>
              <dl>
                <dt>PIN 번호</dt>
                <dd>
                  <span>{{ pinDetail.pin }}</span>
                </dd>
              </dl>
            </div>
          </div>
          <div v-if="pinDetail.buyer" class="lump">
            <div class="table_type02">
              <dl>
                <dt>구매자 정보</dt>
                <dd class="buyer_info">
                  <span>{{ pinDetail.buyer.nick }}</span>
                  <div
                    class="sv_tag"
                    :class="[
                      {
                        kakao:
                          pinDetail.buyer.loginType ===
                          $ConstCode.SNS_LOGIN_TYPE.KAKAO.enName
                      },
                      {
                        naver:
                          pinDetail.buyer.loginType ===
                          $ConstCode.SNS_LOGIN_TYPE.NAVER.enName
                      },
                      {
                        google:
                          pinDetail.buyer.loginType ===
                          $ConstCode.SNS_LOGIN_TYPE.GOOGLE.enName
                      },
                      {
                        apple:
                          pinDetail.buyer.loginType ===
                          $ConstCode.SNS_LOGIN_TYPE.APPLE.enName
                      }
                    ]"
                    v-if="
                      !$Util.isEmpty(pinDetail.buyer.loginType) &&
                        pinDetail.buyer.loginType !== 'wizzney'
                    "
                  >
                    {{
                      pinDetail.buyer.loginType ===
                      $ConstCode.SNS_LOGIN_TYPE.KAKAO.enName
                        ? '카카오'
                        : pinDetail.buyer.loginType ===
                          $ConstCode.SNS_LOGIN_TYPE.NAVER.enName
                        ? '네이버'
                        : pinDetail.buyer.loginType ===
                          $ConstCode.SNS_LOGIN_TYPE.GOOGLE.enName
                        ? '구글'
                        : '애플'
                    }}
                  </div>
                  <!--'카카오: kakao', '네이버: naver', '구글: google'-->
                </dd>
              </dl>
              <dl>
                <dt>결제 방법</dt>
                <dd>
                  <span>{{ pinDetail.buyer.paymentType }}</span>
                </dd>
              </dl>
              <dl>
                <dt>결제일</dt>
                <dd>
                  <span>{{ pinDetail.buyer.payRegDt }}</span>
                </dd>
              </dl>
            </div>
          </div>
          <div class="status" v-if="!$Util.isEmpty(conflict)">
            <div class="inner" v-for="(item, index) in conflict" :key="index">
              <div>
                <dl class="status_sec">
                  <dt class="status_ttl">
                    취소/환불 신청
                    <span
                      class="status_date"
                      :class="{ new: item.modi === '1' }"
                      >{{ $dayjs(item.regDt).format('YYYY-MM-DD') }}</span
                    >
                  </dt>
                  <dd class="status_main">
                    <div class="status_sec_ttl">
                      <div class="reason">
                        <span class="sec_color">{{
                          item.option === '0' ? '단순 변심' : '파티장 과실'
                        }}</span>
                      </div>
                    </div>
                    <div class="editor">
                      <div>
                        {{ item.msg }}
                      </div>
                    </div>
                  </dd>
                  <dd class="status_sub" style="margin-top: 1rem">
                    <div>
                      · 판매자는 취소/환불 신청일로부터 3일 이내에 취소/환불
                      또는 반려 처리해야 합니다. 3일 이후는 자동 취소/환불
                      승인됩니다.
                    </div>
                  </dd>
                </dl>
                <dl class="status_sec" v-if="item.status === '1'">
                  <dt class="status_ttl">
                    취소/환불 안내
                    <span
                      class="status_date"
                      :class="{ new: Number(item.hasBadge) === 1 }"
                      >{{ $dayjs(item.replyRegDt).format('YYYY.MM.DD') }}</span
                    >
                  </dt>
                  <dd class="status_main">
                    <div class="status_sec_ttl">
                      <div class="reason">
                        <span class="color">승인 완료</span>
                      </div>
                    </div>
                    <div class="editor">
                      <div>
                        파티장 (자동) 승인에 따라, 고객님께서
                        {{ $dayjs(item.regDt).format('YYYY년 MM.DD일') }}
                        문의하신 환불 요청 건은 확인 결과 취소 처리
                        하였습니다.<br />
                        {{ pinDetail.buyer.paymentType }} 결제내역이 취소
                        되었습니다.
                      </div>
                    </div>
                  </dd>
                </dl>
                <dl class="status_sec" v-if="item.status === '2'">
                  <dt class="status_ttl">
                    취소/환불 안내
                    <span
                      class="status_date"
                      :class="{ new: Number(item.hasBadge) === 1 }"
                      >{{ $dayjs(item.replyRegDt).format('YYYY.MM.DD') }}</span
                    >
                  </dt>
                  <dd class="status_main">
                    <div class="status_sec_ttl">
                      <div class="reason">
                        <span class="error">신청 반려</span>
                      </div>
                    </div>
                    <div class="editor">
                      <div v-html="item.reply"></div>
                    </div>
                  </dd>
                </dl>
                <dl class="status_sec" v-if="item.status === '3'">
                  <dt class="status_ttl">
                    분쟁 취소 안내
                    <span
                      class="status_date"
                      :class="{ new: Number(item.hasBadge) === 1 }"
                      >{{ $dayjs(item.regDt).format('YYYY.MM.DD') }}</span
                    >
                  </dt>
                  <dd class="status_main">
                    <div class="editor">
                      <div>취소 환불 신청이 취소되었습니다.</div>
                    </div>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
          <div class="con_btn">
            <div class="btn_wrap">
              <button class="btn bo" @click="fnConfirm">
                확인
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { componentState } from './index.js'
export default {
  name: 'my-page-market-view-detail-before-buyer-index',
  setup() {
    return { ...componentState() }
  }
}
</script>

<style scoped></style>
